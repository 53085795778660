import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { injectIntl } from "gatsby-plugin-intl"
import NavBar from "../components/navbar"
import SEO from "../components/SEO/index"
import Slick from "../components/Slick"
import { Section } from "../components/CompoundComponents/Section"
import { Columns, Column } from "../components/CompoundComponents/Columns"
import { Box } from "../components/CompoundComponents/Box"
import {
  Card,
  CardContent,
  CardContentTitle,
} from "../components/CompoundComponents/Card"
import Footer from "../components/Footer/index"

import Bed from "../images/bed.svg"
import Air from "../images/air-conditioner.svg"
import Table from "../images/dining-table.svg"
import Fridge from "../images/fridge.svg"
import Furniture from "../images/furniture.svg"
import LivingRoom from "../images/living-room.svg"
import Tv from "../images/tv.svg"

const LuxuryPage = ({ data, intl }) => {
  return (
    <>
      <SEO
        title="Ниҳол саломатлик сиҳатгоҳи, Санаторий Нихол, Санаторий Учкоргон, Санаторий Узбекистан, Санаторий Наманган, Санаторий Андижан, Санаторий Фергана, Санаторий Ташкент, Санаторий Киргизия, Отдых санаторий Узбекистан, Санаторий Учкурган"
        description="Один из лучших санаториев Узбекистана - Санаторий Нихол, Санаторий Учкоргон, Санаторий Узбекистан, Санаторий Наманган, Санаторий Андижан, Санаторий Фергана, Санаторий Ташкент, Санаторий Киргизия, Отдых санаторий Узбекистан, Санаторий Учкурган, Ниҳол саломатлик сиҳатгоҳи"
      />
      <NavBar />
      <Section>
        <Columns>
          <Column is8>
            <Slick slideToShow={1} slideToScroll={1} speed={3000}>
              {data.luxury.edges.map(item => (
                <Img
                  fluid={item.node.featuredImage.childImageSharp.fluid}
                  alt={item.node.id}
                />
              ))}
            </Slick>
            <Columns>
              <Column>
                <Box centered noshadow>
                  <div>
                    <span className="icon is-large radius-50 has-background-primary mb-1 mr-05">
                      <figure className="image is-flex justify-content-center align-items-center is-48x48">
                        <Tv />
                        {/* <img src={Bed} alt="bed" /> */}
                      </figure>
                    </span>
                    <span className="icon is-large radius-50 has-background-primary mb-1 mr-05">
                      <figure className="image is-flex justify-content-center align-items-center is-48x48">
                        <Air />
                        {/* <img src={Bed} alt="bed" /> */}
                      </figure>
                    </span>
                    <span className="icon is-large radius-50 has-background-primary mb-1 mr-05">
                      <figure className="image is-flex justify-content-center align-items-center is-48x48">
                        <Fridge />
                        {/* <img src={Bed} alt="bed" /> */}
                      </figure>
                    </span>
                    <span className="icon is-large radius-50 has-background-primary mb-1 mr-05">
                      <figure className="image is-flex justify-content-center align-items-center is-48x48">
                        <Bed />
                        {/* <img src={Bed} alt="bed" /> */}
                      </figure>
                    </span>
                    <span className="icon is-large radius-50 has-background-primary mb-1 mr-05">
                      <figure className="image is-flex justify-content-center align-items-center is-48x48">
                        <LivingRoom />
                        {/* <img src={Bed} alt="bed" /> */}
                      </figure>
                    </span>
                    <span className="icon is-large radius-50 has-background-primary mb-1 mr-05">
                      <figure className="image is-flex justify-content-center align-items-center is-48x48">
                        <Furniture />
                        {/* <img src={Bed} alt="bed" /> */}
                      </figure>
                    </span>
                    <span className="icon is-large radius-50 has-background-primary mb-1 mr-05">
                      <figure className="image is-flex justify-content-center align-items-center is-48x48">
                        <Table />
                        {/* <img src={Bed} alt="bed" /> */}
                      </figure>
                    </span>
                  </div>
                </Box>
              </Column>
            </Columns>
          </Column>
          <Column is4>
            <Card mb>
              <CardContent>
                <CardContentTitle>
                  {intl.formatMessage({
                    id: `index.rooms.item-4.title`,
                  })}
                </CardContentTitle>
              </CardContent>
              <footer className="card-footer">
                <span className="card-footer-item is-uppercase is-size-7 has-text-weight-bold">
                  {intl.formatMessage({
                    id: `index.rooms.item-4.room`,
                  })}
                </span>
                <span className="card-footer-item is-uppercase is-size-7 has-text-weight-bold">
                  {intl.formatMessage({
                    id: `index.rooms.item-4.place`,
                  })}
                </span>
              </footer>
              <footer className="card-footer has-text-centered">
                <span className="card-footer-item is-uppercase is-size-7 has-text-weight-bold">
                  {intl.formatMessage({
                    id: `index.rooms.item-4.price`,
                  })}
                </span>
              </footer>
              <footer className="card-footer">
                <Link
                  className="has-text-primary card-footer-item is-uppercase is-size-7 has-text-weight-bold"
                  to="/booking"
                >
                  {intl.formatMessage({
                    id: `header.nav.item-5`,
                  })}
                </Link>
              </footer>
            </Card>
            <article className="message is-danger is-shadow">
              <div className="message-body">
                <p>
                  {intl.formatMessage({
                    id: `treatment.attention.title`,
                  })}
                </p>
                <br />
                <p>
                  {intl.formatMessage({
                    id: `treatment.attention.desc-1`,
                  })}
                </p>
                <br />
                <p>
                  {intl.formatMessage({
                    id: `treatment.attention.desc-2`,
                  })}
                </p>
              </div>
            </article>
          </Column>
        </Columns>
      </Section>
      <Footer data={data.section.edges[0]} />
    </>
  )
}

export const LuxuryPageQuery = graphql`
  query {
    luxury: allLuxuryvipYaml {
      edges {
        node {
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
    section: allProductsYaml {
      edges {
        node {
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  }
`

export default injectIntl(LuxuryPage)
